import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, CircularProgress, Divider, Grid } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

const styles = theme => ({
  card: {
    width: '90%',
    margin: '10px auto 10px',
    borderRadius: '10px'
  },
  cardContent: {
    paddingBottom: '14px !important'
  },
  loader: {
    display: 'block',
    margin: 'auto'
  },
  cardWrapper: {
    display: 'grid',

  },
  cardTitle: {
    color: '#FF6915',
    fontWeight: 'bold',
    margin: 'auto',
    fontSize: '1.8rem'
  },
  advantagesWrapper: {
    width: '90%',
    margin: 'auto',
    '& ul': {
      listStyleType: 'inherit',
      marginTop: '1rem'
    },
    '& li': {
      color: '#4D4D4D',
      marginBottom: '5px'
    }
  },
  divider: {
    backgroundColor: '#4D4D4D',
    height: '3px'
  },
  totalWrapper: {
    width: '85%',
    margin: 'auto',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    '& p': {
      margin: '10px auto 0'
    },
  },
  totalText: {
    color: '#4D4D4D'
  },

 pricePrincipal: {
   fontSize: '2.2rem',
   color: '#FF6915'
 },

 priceTotal:{
   color: '#4D4D4D',
   '& sup': {
     fontSize: '1rem',
   },
   '& span:last-of-type': {
     fontSize: '1rem',
   }
 },

  totalValueWrapper: {
    textAlign: 'right',
  },
  
  period: {
    textAlign: 'right',
    fontSize: '1rem',
    color: '#4D4D4D',
    fontWeight: 'normal',
    marginTop: '-4px !important'
  }
});

class ProductCard extends React.Component {

  constructor(props) {
    super(props);

    this.prices = this.prices.bind(this);
  }
  prices() {
    const { classes } = this.props;
    var priceSplited = "";

    console.log("Propriedades do produto " + this.props.priceAno);
    
    if (this.props.priceAno) {
      console.log("Preco = " + this.props.price);
      var priceFull = parseFloat(this.props.price.replace(",", "."));
      priceFull = priceFull * 12;
      var priceAnoSplited = String(priceFull.toFixed(2)).split('.');
      priceSplited = this.props.price.split(',');
      
      return (
          <p className={classes.totalValueWrapper}>
        <div className={classes.pricePrincipal}>
          <span>12x R$ {priceSplited[0]}{","}{priceSplited[1]}</span><br />
        </div>
        <div className={classes.priceTotal}> 
          <sup>R$ </sup><span>{priceAnoSplited[0]}</span>,<span>{priceAnoSplited[1]}</span> <span>/1º ano</span>
        </div>
        </p>
      );
    } else {
      priceSplited = this.props.price.split(',');

      if (this.props.name.toLocaleLowerCase() === 'digitalanual') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
             <span>12x R$ 3,90</span><br />
             </div>
             <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>46</span>,<span>90</span> <span>/1º ano</span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clube3') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
             <span>12x R$ 6,90</span><br />
             </div>
             <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>82</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clube4') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>12x R$ 5,90</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>70</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clube6') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>12x R$ 8,90</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>106</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if ((this.props.name.toLocaleLowerCase() === 'clube6meses') || (this.props.name.toLocaleLowerCase() === 'clube6mesescs') || (this.props.name.toLocaleLowerCase() === 'clube6mesesloja') ) {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>6x R$ 5,48</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>32</span>,<span>90</span> <span>/6 meses </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clube7') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>12x R$ 3,90</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>46</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if ((this.props.name.toLocaleLowerCase() === 'clubeclientecs') || (this.props.name.toLocaleLowerCase() === 'clubeclienteads') || (this.props.name.toLocaleLowerCase() === 'clubeclienteloja')) {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>12x R$ 0,90</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>10</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clube8') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>12x R$ 22,90</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>274</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clube9') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>12x R$ 7,90</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>94</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clube10') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>12x R$ 17,90</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>214</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clubeoab') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>12x R$ 19,50</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>234</span>,<span>00</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clubesimesc') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>12x R$ 19,50</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>234</span>,<span>00</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clube11') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>R$ 32,90</span>
            </div>
            </p>
        );
      }
      
      if (this.props.name.toLocaleLowerCase() === 'clube12') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>12x R$ 9,90</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>118</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }


      if (this.props.name.toLocaleLowerCase() === 'clube4cs') {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>12x R$ 17,90</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>214</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clube5') {
        return (
          <p className={classes.totalValueWrapper}>
             <div className={classes.pricePrincipal}>
             <span>12x R$ 2,90</span>
             </div>
             <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>34</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'clube2x' || this.props.name.toLocaleLowerCase() === 'clube2xcs') {
        return (
          <p className={classes.totalValueWrapper}>
             <div className={classes.pricePrincipal}>
             <span>2x R$4,90</span>
             </div>
             <div className={classes.priceTotal}> 
             <span>Por 2 meses </span>
            </div>
             <div className={classes.priceTotal}> 
            <sup>+2 meses por R$18,90 /mês</sup>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'digital5') {
        return (
          <p className={classes.totalValueWrapper}>
              <div className={classes.pricePrincipal}>
             <span>12x R$ 1,90</span>
              </div>
              <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>22</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'digital6') {
        return (
          <p className={classes.totalValueWrapper}>
              <div className={classes.pricePrincipal}>
             <span>12x R$ 12,90</span>
              </div>
              <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>154</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      if (this.props.name.toLocaleLowerCase() === 'digital7') {
        return (
          <p className={classes.totalValueWrapper}>
              <div className={classes.pricePrincipal}>
             <span>R$ 14,90</span>
              </div>
          </p>
        );
      }

      if ((this.props.name.toLocaleLowerCase() === 'digital7cs') || (this.props.name.toLocaleLowerCase() === 'digital7ds') || (this.props.name.toLocaleLowerCase() === 'digital7loja') ) {
        return (
          <p className={classes.totalValueWrapper}>
            <div className={classes.pricePrincipal}>
            <span>6x R$ 1,90</span>
            </div>
            <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>11</span>,<span>40</span> <span>/6 meses </span>
            </div>
          </p>
        );
      }

      if (
        this.props.name.toLocaleLowerCase() === 'digitalc1' ||
        this.props.name.toLocaleLowerCase() === 'digitalc2' ||
        this.props.name.toLocaleLowerCase() === 'digitalc3' ||
        this.props.name.toLocaleLowerCase() === 'digitalc4' ||
        this.props.name.toLocaleLowerCase() === 'digitalc5' ||
        this.props.name.toLocaleLowerCase() === 'digitalc6' ||
        this.props.name.toLocaleLowerCase() === 'digitalc7' ||
        this.props.name.toLocaleLowerCase() === 'digitalc8' ||
        this.props.name.toLocaleLowerCase() === 'digitalc9' ||
        this.props.name.toLocaleLowerCase() === 'digitalc10' ||
        this.props.name.toLocaleLowerCase() === 'digitalc11' ||
        this.props.name.toLocaleLowerCase() === 'digitalc12' 
        ) {
        return (
          <p className={classes.totalValueWrapper}>
              <div className={classes.pricePrincipal}>
             <span>R$ 0,90</span>
              </div>
          </p>
        );
      }

      if (
        this.props.name.toLocaleLowerCase() === 'club1' ||
        this.props.name.toLocaleLowerCase() === 'club2' ||
        this.props.name.toLocaleLowerCase() === 'club3' ||
        this.props.name.toLocaleLowerCase() === 'club4' ||
        this.props.name.toLocaleLowerCase() === 'club5' ||
        this.props.name.toLocaleLowerCase() === 'club6' ||
        this.props.name.toLocaleLowerCase() === 'club7' ||
        this.props.name.toLocaleLowerCase() === 'club8' ||
        this.props.name.toLocaleLowerCase() === 'club9' ||
        this.props.name.toLocaleLowerCase() === 'club10' ||
        this.props.name.toLocaleLowerCase() === 'club11' ||
        this.props.name.toLocaleLowerCase() === 'club12' 
        ) {
        return (
          <p className={classes.totalValueWrapper}>
              <div className={classes.pricePrincipal}>
             <span>12x R$ 17,90</span>
              </div>
              <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>214</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }
      

      
      if (this.props.name.toLocaleLowerCase() === 'digital5cs') {
        return (
          <p className={classes.totalValueWrapper}>
              <div className={classes.pricePrincipal}>
             <span>12x R$ 1,90</span>
              </div>
              <div className={classes.priceTotal}> 
            <sup>R$ </sup><span>22</span>,<span>80</span> <span>/1º ano </span>
            </div>
          </p>
        );
      }

      return (
        <p className={classes.totalValueWrapper}>
          <sup>R${" "}</sup><span>{priceSplited[0]}</span>{","}<span>{priceSplited[1]}</span>{" "}<span>{'/mês'}</span>
        </p>
      );


    }

  }

  period() {
    if (this.props.priceAno) {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$" + this.props.priceAno.replace(".", ",");
    }
    if (this.props.name.toLocaleLowerCase() === 'digitalanual') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$178,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clube3') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clube4') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clube6') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }
    if ((this.props.name.toLocaleLowerCase() === 'clube6meses') || (this.props.name.toLocaleLowerCase() === 'clube6mesescs') || (this.props.name.toLocaleLowerCase() === 'clube6mesesloja') ){
      return "Nos primeiros 6 meses. Após o período promocional, será cobrado o valor mensal de 32,90";
    }
    if ((this.props.name.toLocaleLowerCase() === 'digital7ds') || (this.props.name.toLocaleLowerCase() === 'digital7cs') || (this.props.name.toLocaleLowerCase() === 'digital7loja') ){
      return "Nos primeiros 6 meses. Após o período promocional, será cobrado o valor mensal de 14,90/mês";
    }
    if (this.props.name.toLocaleLowerCase() === 'clube7') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }

    if ((this.props.name.toLocaleLowerCase() === 'clubeclientecs') || (this.props.name.toLocaleLowerCase() === 'clubeclienteads') || (this.props.name.toLocaleLowerCase() === 'clubeclienteloja')) {
      return "No 1° ano. Após o período promocional, o valor mensal passa a ser R$32,90";
    }

    if (this.props.name.toLocaleLowerCase() === 'clube8') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clube9') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clube10') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clubeoab') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clubesimesc') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clube12') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clube11') {
      return "";
    }
    if (
        this.props.name.toLocaleLowerCase() === 'club1' ||
        this.props.name.toLocaleLowerCase() === 'club2' ||
        this.props.name.toLocaleLowerCase() === 'club3' ||
        this.props.name.toLocaleLowerCase() === 'club4' ||
        this.props.name.toLocaleLowerCase() === 'club5' ||
        this.props.name.toLocaleLowerCase() === 'club6' ||
        this.props.name.toLocaleLowerCase() === 'club7' ||
        this.props.name.toLocaleLowerCase() === 'club8' ||
        this.props.name.toLocaleLowerCase() === 'club9' ||
        this.props.name.toLocaleLowerCase() === 'club10' ||
        this.props.name.toLocaleLowerCase() === 'club11' ||
        this.props.name.toLocaleLowerCase() === 'club12' 
      )
     {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clube4cs') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$394,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clube5') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$358,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'clube2x' || this.props.name.toLocaleLowerCase() === 'clube2xcs') {
      return "Após o período promocional, o valor mensal passa a ser R$32,90";
    }
    if (this.props.name.toLocaleLowerCase() === 'digital5') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$178,80";
    }
    if (this.props.name.toLocaleLowerCase() === 'digital6') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$178,80";
    }
    if ((this.props.name.toLocaleLowerCase() === 'digital7') || (this.props.name.toLocaleLowerCase() === 'digital7cs') || (this.props.name.toLocaleLowerCase() === 'digital7ds') || (this.props.name.toLocaleLowerCase() === 'digital7loja')) {
      return "";
    }
    if (this.props.name.toLocaleLowerCase() === 'digital5cs') {
      return "No 1° ano. Após o período promocional, o valor anual passa a ser R$178,80";
    }
   if (this.props.price !== this.props.originalPrice) {
      return this.props.period + ". Após o período promocional, o valor mensal passa a ser R$" + this.props.originalPrice+'/mês';
    }

    return "";
  }

  render() {
    const { classes } = this.props;
    return (
      <Card classes={{ root: classes.card }}>
        <CardContent classes={{ root: classes.cardContent }}>
          {
            this.props.loading ?
              <CircularProgress classes={{ root: classes.loader }} color="secondary" /> :
              <div className={classes.cardWrapper}>
                <div className={classes.cardTitle}>{this.props.title}</div>
                <div className={classes.advantagesWrapper}>
                  <ul>
                    {this.props.advantages.map((i) => <li>{ReactHtmlParser(i)}</li>)}
                  </ul>
                </div>
                <Divider classes={{ root: classes.divider }} variant="middle" />
                <div className={classes.totalWrapper}>
                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    alignItems="center">
                    <Grid item xs={4}>
                      <p className={classes.totalText}>Total</p>
                    </Grid>
                    <Grid item xs={8}>
                      {this.prices()}
                    </Grid>
                    <Grid item xs={12}>
                      <p className={classes.period}>{this.period()}</p>
                    </Grid>
                  </Grid>
                </div>
              </div>
          }
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(ProductCard);

