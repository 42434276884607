
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpansionPanelSummary,
  Typography,
  CircularProgress
} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from '@material-ui/core/styles/withStyles';
import CreditCardType from 'credit-card-type';
import Redirect from 'react-router-dom/Redirect';
import MaskedInput from 'react-text-mask';
import CircularLoader from '../Components/CircularLoader';
import UrlHelper from '../Helpers/UrlHelper';
import { FormatDate, FormatInvertDateTime, IsValidDate } from '../Helpers/Utils';
import ApiService from '../Services/ApiService';
import ProductService from '../Services/ProductService';
import UserSession from '../Services/UserSession';
import ProductCard from '../Components/ProductCard';
import { Helmet } from 'react-helmet';
import Autocomplete from 'react-autocomplete';
import JornalAssinatura from '../Components/JornalAssinatura';
import imgDC from '../img/DC.png';
import imgAN from '../img/AN.png';
import imgJSC from '../img/JSC.png';

const styles = theme => ({
  containerFormulario: {
    width: '90%',
    maxWidth: 468,
    minHeight: 600,
    background: 'white',
    paddingBottom: 55,
    margin: '0px auto',
    position: 'relative',
    textAlign: 'left'
  },
  containerSteps: {
    width: '80%',
    paddingTop: 58,
    position: 'relative',
    margin: '0px auto',
    marginBottom: 50
  },
  stepAtivo: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    background: '#848585',
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
    float: 'left',
    position: 'relative',
    zIndex: 10,
  },
  step: {
    width: 23,
    height: 23,
    borderRadius: '50%',
    background: 'white',
    border: '2px solid #848585',
    color: '#848585',
    fontSize: 18,
    fontWeight: 'bold',
    position: 'relative',
    zIndex: 10,
    float: 'right',
    marginTop: -5,
    marginRight: -4,
  },
  linhaSteps: {
    width: '100%',
    border: '2px solid #848585',
    position: 'relative',
    margin: '0px auto',
    top: 10,
    zIndex: 1,
  },
  tituloCategoria: {
    fontWeight: 'normal',
    fontSize: 32,
    marginBottom: 30,
    marginTop: 40
  },
  marcadorTitulo: {
    borderRadius: '50%',
    background: '#EE6B25',
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
    paddingTop: 2,
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 10,
    top: -3,
    marginRight: 10,
    position: 'relative'
  },
  boxJornals: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'wrap'
  },
  inpForm: {
    width: '100%',
    height: 30,
    border: '1px solid #C0C0C0',
    borderRadius: 2,
    paddingLeft: 10,
    marginBottom: 20,
  },
  inpForm2: {
    width: '100%',
    height: 30,
    border: '1px solid #C0C0C0',
    borderRadius: 2,
    paddingLeft: 10,
  },
  inpFormErro: {
    width: '100%',
    height: 30,
    border: '1px solid #8e0710',
    borderRadius: 2,
    paddingLeft: 10,
    marginBottom: 20,
  },
  inpFormErro2: {
    width: '100%',
    height: 30,
    border: '1px solid #8e0710',
    borderRadius: 2,
    paddingLeft: 10,
  },
  boxExpiration: {
    width: '30%',
    float: 'left',
  },
  itemAutocomplete: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'white'
    }
  },
  autocomplete: {
    marginTop: '-21px',
    backgroundColor: '#e4e4e4',
    padding: '0px 10px 10px 10px',
    borderRadius: '0px 0px 10px 10px'
  },
  boxState: {
    width: '20%',
    float: 'right',
  },
  boxAddressType: {
    width: '42%',
    float: 'left',
  },
  boxCity: {
    width: '50%',
    float: 'left',
  },
  boxReferece: {
    width: '100%',
    float: 'left',
  },
  boxStreet: {
    width: '70%',
    float: 'left',
  },
  boxNeighborhood: {
    width: '45%',
    float: 'right',
  },
  boxBtnCep: {
    width: '40%',
    float: 'right',
  },
  boxCep: {
    paddingRight: '2%',
    width: '35%',
    float: 'right',
    marginLeft: '10px'
  },
  boxAddressNumber: {
    width: '25%',
    float: 'right'
  },
  boxSecurityCode: {
    width: '60%',
    float: 'right',
  },
  cmbForm: {
    width: 'calc(100% + 15px)',
    height: 35,
    border: '1px solid #C0C0C0',
    borderRadius: 2,
    paddingLeft: 10,
    marginBottom: 20,
  },
  cmbFormError: {
    width: 'calc(100% + 15px)',
    height: 35,
    border: '1px solid #8e0710',
    borderRadius: 2,
    paddingLeft: 10,
    marginBottom: 20,
  },
  lblForm: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#848585',
    display: 'block',
    paddingBottom: 0
  },
  parSubtituloProduto: {
    marginBottom: 0,
    color: '#4D4D4D',
  },
  spnSubtituloProduto: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  btnComprar: {
    width: '100%',
    height: 46,
    border: 0,
    background: '#EE6B25',
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
    borderRadius: 10,
    cursor: 'pointer',
    marginLeft: 5,
    '&:hover': {
      textDecoration:'none',
      background: '#d5510b',
    }
  },
  btnVoltar: {
    width: '100%',
    background: '#EE6B25',
    fontSize: 16,
    color: 'white',
    borderRadius: 10,
    cursor: 'pointer',
    marginLeft: 5,
    padding: 13,
    textAlign: 'center',
    marginTop: 5,
    '&:hover': {
      textDecoration: 'none',
      background: '#d5510b',
    }
  },
  btnCep: {
    width: '100%',
    marginTop: '30px',
    height: 28,
    border: 0,
    background: '#EE6B25',
    fontSize: 13,
    color: 'white',
    fontWeight: 'bold',
    borderRadius: 10,
    cursor: 'pointer',
  },
  btnCepDisabled: {
    width: '100%',
    marginTop: '30px',
    height: 28,
    border: 0,
    background: '#b5b5b5',
    fontSize: 13,
    color: 'white',
    fontWeight: 'bold',
    borderRadius: 10,
    cursor: 'pointer',
  },
  loaderCompra: {
    width: '100%',
    textAlign: 'center'
  },
  tituloErro: {
    color: '#a80b16'
  },
  root: {
    color: "#4C4C4C",
    paddingLeft: 0,
    "&$checked": {
      color: "#EE6B25"
    }
  },
  checked: {},
  boxRadPlano: {
    float: 'left',
    width: 40,
    clear: 'both',
    marginTop: 5,
    textAlign: 'left',
  },
  boxCheckbox: {
    float: 'left',
    width: 40,
    clear: 'both',
    margin: '5px 0',
    textAlign: 'left',
  },
  boxConteudoPlano: {
    float: 'left',
    width: 'calc(100% - 40px)',
  },
  containerLoader: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    background: 'white',
    filter: 'opacity(50%)',
    textAlign: 'center',
    zIndex: 9999,
  },
  boxLoader: {
    position: 'relative',
    margin: '0px auto',
    width: 40,
    height: 40,
    top: '35%',
  },
  ulItens: {
    margin: 0,
    padding: 0,
    color: '#4C4C4C',
    fontSize: 18,
    textAlign: 'left',
    marginLeft: 15

  },
  liItens: {
    margin: 0,
    padding: 0,
    lineHeight: '120%',

  },
  spnItem: {
    color: '#4D4D4D',
    fontSize: 14,
  },
  msgOfertaValidade: {
    margin: 0,
    marginBottom: 10,
    padding: 0,
    fontSize: 11
  },
  heading: {
    color: '#848585',
    fontSize: '1.5rem'
  },
  term: {
    color: '#4D4D4D',
    fontSize: '.9rem',
    textAlign: 'center',
    '@media (max-width: 450px)': {
      fontSize: '.7rem'
    }
  },
  radioPaymentType1: {
    marginBottom: '40px',
    marginRight: '5px',
    '@media (max-width: 450px)': {
      marginBottom: '20px',
    }
  },
  radioPaymentType1div: {
    width: '40%',
    float: 'left',
    '@media (max-width: 450px)': {
      width: '100%',
    }
  },
  radioPaymentType2div: {
    width: '60%',
    float: 'left',
    '@media (max-width: 450px)': {
      width: '100%',
    }
  },
  radioPaymentType2: {
    marginBottom: '40px',
    marginRight: '5px',
    '@media (max-width: 450px)': {
      marginLeft: '0px'
    }
  },
  paymentFormDisplayNone: {
    display: 'none'
  },
  bankSelect: {
    float: 'left',
    width: '100%',
    backgroundColor: '#fff',
    borderColor: '#c0c0c0',
    height: '30px',
  },
  bankDataDiv: {
    float: 'left',
    width: '32%',
    marginRight: '3%',
    marginTop: '20px',
  },
  bankDataDivLast: {
    float: 'left',
    width: '30%',
    marginTop: '20px',
  },
  bankInfoLabel: {
    color: '#848590',
    fontWeight: '600',
    textAlign: 'center',
    fontSize: '13px',
    marginTop: '8px',
    marginBottom: '0px'
  },
  bankAgencyLabel: {
    color: '#4D4D4D',
    fontSize: '.9rem',
    textAlign: 'center'
  },
});

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto',
  },
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    display: 'block'
  }
}))(MuiExpansionPanelDetails);

class Loja extends React.Component {

  errors_redirect = [
    'SUBSCRIPTION_ATTEMPTS_EXCEEDED',
    'SUBSCRIPTION_ALREADY_EXISTS',
    'PENDING_SUBSCRIPTION_EXISTS',
    'PENDING_PAYMENT',
    'DUPLICATED_ORDER',
    'INTERNAL_ERROR',
    'ADDRESS_NOT_ACCEPTED',
    'DELIVERY_MODE_NOT_ACCEPTED'
  ]

  constructor(props) {
    super(props);
    this.state = {
      user: {},
      userAccordionExpanded: false,
      payment: {},
      cities: [],
      neighborhoods: [],
      complements: [],
      streets: [],
      redirectTo: false,
      openAlert: false,
      titleAlert: '',
      contentAlert: '',
      redirectOnClose: false,
      showLoader: true,
      form_errors: [],
      retry_google: true,
      urlState: UrlHelper.getQueryString('url_state'),
      productName: this.props.product,
      settingNeigborhoods: false,
      paymentForm: 1,
      banks: [],
      product: {
        title: '',
        advantages: [],
        originalPrice: '',
        price: '',
        period: ''
      },
      loadingProduct: true
    }

    this.handlerChange = this.handlerChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChangeChkPaymentType = this.handleChangeChkPaymentType.bind(this);
    this.validateRequest = this.validateRequest.bind(this);
    this.handleExpandUserAccordion = this.handleExpandUserAccordion.bind(this);
    this.handleSetProduct = this.handleSetProduct.bind(this);
    this.handleSetBanks = this.handleSetBanks.bind(this);
  }

  async componentDidMount() {
    await this.handleSetProduct();
    let user = await UserSession.getUser();

    if (user) {
      if (user.products) {
        if (user.products.split(';')[0] != "") {
          window.location.href = UrlHelper.buildLoginUrl('conclusao-digital/SUBSCRIPTION_ALREADY_EXISTS');
        }
      }
    }

    if (!user) {
      let path = '';
      switch (this.state.productName) {
        case 'digital':
          const urlParams = new URLSearchParams(window.location.search);
          const token = urlParams.get('token');
          path = '/loja-digital?token=' + token;
          break;
        case 'clube':
          path = '/loja-digital-clube';
          break;
        case 'clube2':
          path = '/loja-nsc-total';
          break;
        case 'clubepromo':
          path = '/loja-clube-degustacao';
          break;
        case 'clube2cs':
          path = '/loja-nsc-total-cs';
          break;
        case 'clube3':
          path = '/loja-clube-anual';
          break;
        case 'clube4':
           path = '/clube-nsc-anual';
           break;
        case 'clube6':
           path = '/loja-clube-anual-brinde';
           break;
        case 'clube6meses':
          path = '/loja-nsc-total-seis-meses';
          break;
        case 'clube6mesescs':
          path = '/loja-nsc-total-seis-meses-cs';
          break;
        case 'clube6mesesloja':
          path = '/loja-nsc-total-seis-meses-loja';
          break;
        case 'clubeweekads':
          path = '/clube-nsc-week-ads';
          break;
        case 'clubeweekcs':
          path = '/clube-nsc-week-cs';
          break;
        case 'clubeweekloja':
          path = '/clube-nsc-week-loja';
          break;
        case 'clube7':
          path = '/clube-nsc-anual-oferta-especial';
          break;
        case 'clubeclientecs':
          path = '/clube-nsc-anual-mes-do-cliente-cs';
          break;
        case 'clubeclienteads':
          path = '/clube-nsc-anual-mes-do-cliente-ads';
          break;
        case 'clubeclienteloja':
          path = '/clube-nsc-anual-mes-do-cliente-loja';
          break;
        case 'clube8':
          path = '/clube-nsc-anual-padrao';
          break;
        case 'digital6':
          path = '/nsc-total-light-anual-padrao';
          break;
        case 'clube9':
          path = '/clube-nsc-anual-7-90';
          break;
        case 'clube10':
          path = '/clube-nsc-anual-17-90';
          break;
        case 'clubeoab':
          path = '/clube-nsc-anual-oab';
          break;
        case 'clubesimesc':
          path = '/clube-nsc-anual-simesc';
          break;
        case 'clubeuni':
          path = '/clube-nsc-universidades';
          break;
        case 'clube11':
          path = '/loja-nsc-total-padrao';
          break;
        case 'clube12':
          path = '/loja-nsc-total-diadoconsumidor';
          break;
        case 'digital7':
          path = '/loja-digital-padrao';
          break;
        case 'digital7cs':
          path = '/loja-digital-padrao-cs';
          break;
        case 'digital7ds':
          path = '/loja-digital-padrao-ads';
          break;
        case 'digital7loja':
          path = '/loja-digital-padrao-loja';
          break;
        case 'clube4cs':
          path = '/clube-nsc-anual-cs';
          break;
        case 'clube5':
          path = '/summit-cidades';
          break;
        case 'clube2x':
          path = '/loja-clube-desconto';
          break;
        case 'clube2xcs':
          path = '/loja-clube-desconto-cs';
          break;
        case 'clubebrinde':
          path = '/assine-clube-nsc-anual-brinde';
          break;
        case 'digital5':
          path = '/nsc-total-light-anual';
          break;
        case 'digital5cs':
          path = '/nsc-total-light-anual-cs';
          break;
        case 'combolight':
          path = '/loja-combo-light';
          break;
        case 'digitalanual':
          path = '/loja-digital-anual';
          break;
        case 'digital2':
          path = '/loja-digital-nsc';
          break;
        case 'digital2cs':
          path = '/loja-digital-nsc-cs';
          break;
        case 'digital3':
          path = '/loja-digital-nsc-total';
          break;
        case 'digital4':
          path = '/loja-digital-total';
          break;
        case 'globo':
          path = '/loja-nsc-globo-mais';
          break;
        case 'globoanual':
          path = '/nsc-globo-anual';
          break;
        case 'club1':
          path = '/loja-clube-silva';
          break;
        case 'club2':
          path = '/loja-clube-seabra';
          break;
        case 'club3':
          path = '/loja-clube-spautz';
          break;
        case 'club4':
          path = '/loja-clube-benetti';
          break;
        case 'club5':
          path = '/loja-clube-faraco';
          break;
        case 'club6':
          path = '/loja-clube-nasser';
          break;
        case 'club7':
          path = '/loja-clube-coelho';
          break;
        case 'club8':
          path = '/loja-clube-uggioni';
          break;
        case 'club9':
          path = '/loja-clube-machado';
          break;
        case 'club10':
          path = '/loja-clube-igor';
          break;
        case 'club11':
          path = '/loja-clube-saavedra';
          break;
        case 'club12':
          path = '/loja-clube-ze';
          break;
        case 'digitalc1':
          path = '/loja-digital-silva';
          break;
        case 'digitalc2':
          path = '/loja-digital-seabra';
          break;
        case 'digitalc3':
          path = '/loja-digital-spautz';
          break;
        case 'digitalc4':
          path = '/loja-digital-benetti';
          break;
        case 'digitalc5':
          path = '/loja-digital-faraco';
          break;
        case 'digitalc6':
          path = '/loja-digital-nasser';
          break;
        case 'digitalc7':
          path = '/loja-digital-coelho';
          break;
        case 'digitalc8':
          path = '/loja-digital-uggioni';
          break;
        case 'digitalc9':
          path = '/loja-digital-machado';
          break;
        case 'digitalc10':
          path = '/loja-digital-igor';
          break;
        case 'digitalc11':
          path = '/loja-digital-saavedra';
          break;
        case 'digitalc12':
          path = '/loja-digital-ze';
          break;
        default:
          throw new Error('Produto desconhecido');
      }
      window.location.href = UrlHelper.buildLoginUrl(path);
    }
    if (this.state.productName === 'combolight') {
      await this.handleSetCities();
      await this.handleSetComplements();
    }

    await this.handleSetBanks();

    this.setState({
      user: user,
      userAccordionExpanded: (!user.document || !user.phone_number_1 ||
        !user.birthdate || !user.family_name || !user.given_name),
      showLoader: false,
      loadingProduct: false
    });

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY_V3}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    let recaptchaScript = document.querySelector('script[src*="recaptcha/api.js"]');
    if (recaptchaScript) {
      recaptchaScript.remove();
    }
  }

  handleClick = (name) => (event) => {
    switch (name) {
      case 'btnCep':
        this.handleSetCep()
        break;

      default:
        break;
    }
  }

  async handleSetCep() {
    console.log('busca cep', this.state.user.cep);
  }

  async handleSetCities() {
    let cities = await ApiService.getCities('SC');
    cities = cities.sort((a, b) => a.nome < b.nome ? -1 : 1);
    this.setState({ cities: cities });
  }

  async handleSetComplements() {
    let complements = await ApiService.getComplements();
    this.setState({ complements });
  }

  async handleSetBanks() {
    let newspaper = this.state.user.channel;
    if (!newspaper) {
      newspaper = 2;
    }
    let banks = await ApiService.getBanks(newspaper);
    this.setState({ banks });
  }
  async handleSetProduct() {
    let product = await ApiService.getProduct({ product: this.state.productName });

    let description = '';
    let title = '';

    switch (this.state.productName.toLowerCase()) {
      case 'digitalanual':
        title = 'Assinatura digital anual | Assine o NSC Total';
      case 'digital':
        description = 'Assinatura digital da NSC concede acesso ilimitado ao NSC Total e benefícios em centenas de estabelecimentos catarinenses.';
        title = 'Assinatura digital | Assine o NSC Total';
        break;

      case 'clube':
        description = 'Assinatura digital da NSC concede acesso ilimitado ao NSC Total e aos serviços de saúde à distância da TopMed.';
        title = 'Assinatura digital + beneficios do Clube NSC | Assine o NSC Total';
        break;

      case 'combolight':
        description = 'Assinatura digital da NSC concede acesso ilimitado ao NSC Total e aos serviços de saúde à distância da TopMed.';
        title = 'Assinatura digital | Assine o NSC Total';
        break;
      
      case 'digital5':
         description = 'Assinatura digital da NSC concede acesso ilimitado ao NSC Total.';
         title = 'Assinatura digital | Assine o NSC Total';
         break;

      case 'digital5cs':
        description = 'Assinatura digital da NSC concede acesso ilimitado ao NSC Total.';
        title = 'Assinatura digital | Assine o NSC Total';
        break;

      default:
        break;
    }

    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');
    if (token) {
      token = token.replace("?url_state=", "");
    }
    let price = product.price,
      discountClube = product.discountClube,
      discount = product.discount,
      discountPeriod = product.discountPeriod;

    switch (token) {
      case 'aa5qwe48asd1q2g2v1bgfh5gh87hg9fg': discount = 14; break;
      case '4s8d4a5q2w2c63vxc8b1zxc8qhfczwra': discount = 11; discountPeriod = 2; break;
      case 'q1we23645asd978sdg9af132qwdf4g5j': discount = 12; discountPeriod = 2; break;
      case 'bm3n14g65hj7ert3sz12asd46qw978ec': discount = 12; discountPeriod = 1; break;
      case 'a78tn31m456hgj8g6sdf1f8ew12q88s9': discount = 13; discountPeriod = 1; break;
    }

    let originalPrice = parseFloat(product.price).toLocaleString("pt-BR", { minimumFractionDigits: 2 });

    this.setState({
      product: {
        title: title,
        description: description,
        name: ProductService.getTitle(product.name),
        advantages: ProductService.getAdvantages(product.name),
        originalPrice: originalPrice,
        price: ProductService.buildPrice(price, [discountClube, discount]),
        period: ProductService.getPeriod(discountPeriod),
        priceAno: product.priceAno
      },
    });

  }

  handleExpandUserAccordion(event, expanded) {
    this.setState({ userAccordionExpanded: expanded });
  }

  async validateRequest(objOrder) {
    let openAlert = false;
    const titleAlert = 'Formulário';
    let contentAlert = 'Identificamos algumas informações inválidas, por favor revise.';

    const timeToday = new Date().getTime();
    const timeBirthday = new Date(objOrder.attributes.birthdate).getTime();
    const isValidDate = true;//IsValidDate(this.state.user.birthdate);
    const isValidExpiration = objOrder.payment.expiration_date_card ? IsValidDate(FormatDate(objOrder.payment.expiration_date_card)) : false;

    const arrErros = [];

    if (!objOrder.payment.card_type && this.state.paymentForm == 1) {
      openAlert = true;
      contentAlert = 'Cartão inválido, por favor revise';
      arrErros.push('card_number');
    }

    // if (!this.state.user.clube && !this.state.user.clube !== "0") {
    //   openAlert = true;
    //   contentAlert = 'Por favor, selecione o produto que deseja comprar';
    //   arrErros.push('clube');
    // }

    if (!objOrder.attributes.given_name) {
      openAlert = true;
      arrErros.push('given_name');
    }

    if (!objOrder.attributes.family_name) {
      openAlert = true;
      arrErros.push('family_name');
    }

    if (!objOrder.attributes.email) {
      openAlert = true;
      arrErros.push('email');
    }

    if (!objOrder.attributes.document) {
      openAlert = true;
      arrErros.push('document');
    }

    if (
      objOrder.attributes.document &&
      ((objOrder.attributes.document.length < 14 &&
        !this.TestaCPF(objOrder.attributes.document)) ||
        (objOrder.attributes.document.length !== 11 && objOrder.attributes.document.length !== 14))) {
      openAlert = true;
      arrErros.push('document');
    }

    if (!objOrder.attributes.birthdate) {
      openAlert = true;
      arrErros.push('birthdate');
    }

    if (!this.state.user.birthdate || (timeBirthday > timeToday) || !isValidDate) {
      openAlert = true;
      arrErros.push('birthdate');
    }

    // if (!objOrder.attributes.phone_number_1) {
    //   openAlert = true;
    //   arrErros.push('phone_number_1');
    // }

    // if (!/^(?:(\+55\d{2})|\d{2})(9)\d{8}$/.test(objOrder.attributes.phone_number_1)) {
    //   openAlert = true;
    //   arrErros.push('phone_number_1');
    // }

    /* if (!objOrder.attributes.gender) {
      openAlert = true;
      arrErros.push('gender');
    } */

    if (!objOrder.payment.name_card && this.state.paymentForm == 1) {
      openAlert = true;
      arrErros.push('name_card');
    }

    if (!objOrder.payment.card_number && this.state.paymentForm == 1) {
      openAlert = true;
      arrErros.push('card_number');
    }

    if ((!objOrder.payment.expiration_date_card || !isValidExpiration) && this.state.paymentForm == 1) {
      openAlert = true;
      arrErros.push('expiration_date_card');
    }

    if (this.state.paymentForm == 2) {

      if (!objOrder.payment.bank) {
        openAlert = true;
        arrErros.push('bank');
      }

      if (!objOrder.payment.bank_account) {
        openAlert = true;
        arrErros.push('bank_account');
      }

      if (!objOrder.payment.bank_agency) {
        openAlert = true;
        arrErros.push('bank_agency');
      }

      if (!objOrder.payment.bank_digit) {
        openAlert = true;
        arrErros.push('bank_digit');
      }

      if (objOrder.payment.bank
        && objOrder.payment.bank_agency
        && objOrder.payment.bank_account
      ) {

        let accountVerify = await ApiService.getAccountVerify(
          2,
          9,
          objOrder.payment.bank,
          objOrder.payment.bank_agency,
          objOrder.payment.bank_account + "" + objOrder.payment.bank_digit
        );
        if (!accountVerify) {
          arrErros.push('bank_account');
          arrErros.push('bank_agency');
          contentAlert = 'Dados bancários inválidos';
          openAlert = true;
        }
      }
    }

    if (this.state.productName === 'combolight') {

      if (!objOrder.payment.code_card) {
        openAlert = true;
        arrErros.push('code_card');
      }

      if (!objOrder.address.state_code) {
        openAlert = true;
        arrErros.push('state_code');
      }

      if (!objOrder.address.address_type || objOrder.address.address_type === 'select') {
        openAlert = true;
        arrErros.push('address_type');
      }

      if (!objOrder.address.street_code || objOrder.address.street_code === 'select') {
        openAlert = true;
        arrErros.push('street_code');
      }

      if (!objOrder.address.address_number) {
        openAlert = true;
        arrErros.push('address_number');
      }

      if (!objOrder.address.neighborhood_code || objOrder.address.neighborhood_code === 'select') {
        openAlert = true;
        arrErros.push('neighborhood_code');
      }

      if (!objOrder.address.city_code || objOrder.address.city_code === 'select') {
        openAlert = true;
        arrErros.push('city_code');
      }

      if (!objOrder.address.cep) {
        openAlert = true;
        arrErros.push('cep');
      }

      if (!objOrder.product.channel) {
        openAlert = true;
        arrErros.push('channel');
        contentAlert = 'Escolha seu jornal.';
      }
    }

    if (openAlert) {
      this.setState({ openAlert, titleAlert, contentAlert, form_errors: arrErros });
      console.log("RETURN FALSE");
      return false;
    } else {
      this.setState({ form_errors: arrErros });
      return true;
    }
  }

  async submitOrder() {
    this.setState({ showLoader: true });
    const user = this.state.user;
    this.state.user.clube = this.state.productName.toLowerCase() === 'clube' ? 1 : 0;    
    const recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY_V3, {action: 'submit'});
    
    if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(user.birthdate)) {
      user.birthdate = FormatInvertDateTime(user.birthdate);
    }

    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');
    if (token) {
      token = token.replace("?url_state=", "");
    }
    const objOrder = {
      token: token,
      product: {
        name: this.state.productName,
        channel: this.state.user.channel
      },
      attributes: {
        given_name: user.given_name,
        family_name: user.family_name,
        email: user.email,
        document: user.document ? user.document.replace(/[./\-_]+/g, '').trim() : null,
        birthdate: user.birthdate ? user.birthdate : null,
        gender: null,
        phone_number_1: user.phone_number_1 ? (user.phone_number_1).replace(/[^\d]/g, '') : '',
        clube: user.clube
      },
      payment: {
        name_card: user.name_card,
        card_number: user.card_number ? user.card_number.trim().replace(/\s/g, '') : null,
        expiration_date_card: user.expiration_date_card ? FormatInvertDateTime('01/' + user.expiration_date_card) : null,
        code_card: user.code_card,
        card_type: user.card_type,
        payment_type: this.state.paymentForm,
        bank: user.bank,
        bank_account: user.bank_account ? user.bank_account.replace(/[.\-_]/g, "") : null,
        bank_agency: user.bank_agency ? user.bank_agency.replace(/[.\-_]/g, "") : null,
        bank_digit: user.bank_digit ? user.bank_digit.replace(/[.\-_]/g, "") : null
      },
      address: {
        state_code: 'SC',
        address_type: this.state.user.address_type,
        street_code: this.state.user.street_code,
        address_number: this.state.user.address_number,
        neighborhood_code: this.state.user.neighborhood_code,
        city_code: this.state.user.city_code,
        cep: this.state.user.cep ? this.state.user.cep.replace(/[.\-_]/g, '') : null,
        complement_type: this.state.user.complement_type,
        complement_number: this.state.user.complement_number,
        complement_block: this.state.user.complement_block,
        complement_reference: this.state.user.complement_reference,
      },

      recaptchaToken
      
    }
    
   if (!await this.validateRequest(objOrder)) {
      this.setState({ showLoader: false });
      return false;
    }

    window.scrollTo(0, 0);

    ApiService.order(objOrder).then(async obj => {
      if (obj.status === 200) {
        this.setState({ showLoader: false });

        let page;

        switch (this.state.productName.toLowerCase()) {
          case 'digitalanual':
            page = '/conclusao-digital';
            break;

          case 'combolight':
            page = '/conclusao-combo-light';
            break;

          case 'clube':
            page = '/conclusao-digital-clube';
            break;

          case 'globo':
            page = '/conclusao-nsc-globo-mais';
            break;

          default:
            page = '/conclusao-digital';
            break;
        }

        window.location.href = UrlHelper.buildUrl(page, {
          url_state: UrlHelper.getQueryString('url_state'),
          from_store: true
        });
      } else if(obj.status === 400){
        let page = '/conclusao-digital/';
        this.setState({redirectTo: page + 'PENDING_PAYMENT'});
        return false;
      }else{
        if (obj.as_error && this.errors_redirect.indexOf(obj.as_error) >= 0) {
          let page = '/conclusao-digital/';
          this.setState({ redirectTo: page + obj.as_error });
          return false;
        }

        const openAlert = true;
        const titleAlert = 'Erro!';
        const contentAlert = obj.message;
        this.setState({ showLoader: false, openAlert, titleAlert, contentAlert });
      }
    })
  }

  requestTimer = null;

  async handleSetStreets() {
    const name = this.state.user.street_name;
    const filtraQuemComecaIgual = street => street.nome.substr(0, name.length) === name.toUpperCase();
    const ordernaPeloTamanhoDoNome = (street1, street2) => street1.nome.length > street2.nome.length ? 1 : -1;
    try {
      let streets = await ApiService.getStreets('SC', this.state.user.city_code, name);
      let mostEqualStreets = streets
        .filter(filtraQuemComecaIgual)
        .sort(ordernaPeloTamanhoDoNome)
        .slice(0, 9);
      this.setState({ streets: mostEqualStreets });
    } catch (error) {
      console.error(error)
      this.setState({ streets: [] });
    }
  }

  handlerChange = (name) => (event) => {
    let value = event.target.value;
    const user = this.state.user;
    const regex = /[0-9]/g;
    if (name === 'card_number') {
      const objCreditCardType = CreditCardType(value.replace(/\s/g, ''))[0];
      if (objCreditCardType) {
        user.card_type = objCreditCardType.type
      }
    } else if (name === 'document' && value.replace(/[./\-_]+/g, '').trim().length > 11 && user.clube === "1") {
      user.clube = null;
    }

    user[name] = value;
    this.setState({ user })

    if (name === 'city_code') {
      this.handleSetNeighborhoods();
      user.street_code = null;
      user.neighborhood_code = 'select';
      this.setState({ user })
    }

    if (name === 'neighborhood_code') {
      user.street_code = null;
      this.setState({ user })
    }

    if (name === 'street_name') {
      clearTimeout(this.requestTimer)
      this.requestTimer = this.handleSetStreets();
    }
  }

  async handleSetNeighborhoods() {
    this.setState({ settingNeigborhoods: true });
    try {
      let neighborhoods = await ApiService.getNeighborhoods('SC', this.state.user.city_code);
      neighborhoods = neighborhoods.sort((a, b) => a.nome < b.nome ? -1 : 1);
      this.setState({ neighborhoods: neighborhoods, settingNeigborhoods: false });
    } catch (error) {
      this.setState({ neighborhoods: [], settingNeigborhoods: false });
    }
  }

  renderRedirect = () => {
    if (this.state.redirectTo) {
      return <Redirect to={UrlHelper.buildUrl(this.state.redirectTo, { url_state: this.state.urlState })} />
    }
  }

  handleClose = () => {
    if (this.state.redirectOnClose) {
      this.setState({ redirectTo: this.state.redirectOnClose });
    }

    this.setState({ openAlert: false });
  }

  TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF.match("([0-9])\\1{10}")) return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  formatDate(date) {
    if (!date) {
      return false;
    }

    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (d.getDate() + 1),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
  }

  formatPhoneNumber(phoneNumber) {
    return phoneNumber.replace("+55", "");
  }

  handleChangeChkPaymentType(event) {
    this.setState({ paymentForm: event.target.value });
  }

  render() {
    const { classes } = this.props;
    const { userAccordionExpanded } = this.state;

    let maskCard = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];

    if (this.state.user.card_type === 'diners-club') {
      maskCard = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
    } else if (this.state.user.card_type === 'american-express') {
      maskCard = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];
    }

    return (
      <div>
        <Helmet>
          <title>
            {this.state.product.title}
          </title>
          <meta
            name="description"
            content={this.state.product.description} />
        </Helmet>
        {this.renderRedirect()}
        <div className={classes.containerLoader} style={!this.state.showLoader ? { display: 'none' } : {}}>
          <div className={classes.boxLoader}>
            <CircularLoader className={classes.loader} />
          </div>
        </div>
        <Dialog
          open={this.state.openAlert}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span className={classes.tituloErro}>{this.state.titleAlert}</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.contentAlert}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="default" autoFocus>Fechar</Button>
          </DialogActions>
        </Dialog>

        <div className={classes.containerFormulario}>

          <ProductCard
            name={this.state.productName}
            loading={this.state.loadingProduct}
            title={this.state.product.name}
            advantages={this.state.product.advantages}
            price={this.state.product.price}
            period={this.state.product.period}
            priceAno={this.state.product.priceAno ? this.state.product.priceAno : null}
            originalPrice={this.state.product.originalPrice}
          />
{
this.state.productName !== 'clube' && 
this.state.productName !== 'clube2' && 
this.state.productName !== 'clube2cs' && 
this.state.productName !== 'clubepromo' && 
this.state.productName !== 'clubeuni' &&  
this.state.productName !== 'clube3' &&  
this.state.productName !== 'clube4' && 
this.state.productName !== 'clube6' && 
this.state.productName !== 'clube6meses' && 
this.state.productName !== 'clube6mesescs' && 
this.state.productName !== 'clube6mesesloja' && 
this.state.productName !== 'clubeweekads' && 
this.state.productName !== 'clubeweekcs' && 
this.state.productName !== 'clubeweekloja' && 
this.state.productName !== 'clube7' && 
this.state.productName !== 'clubeclientecs' && 
this.state.productName !== 'clubeclienteads' && 
this.state.productName !== 'clubeclienteloja' && 
this.state.productName !== 'clube8' && 
this.state.productName !== 'clube9' && 
this.state.productName !== 'clube10' &&
this.state.productName !== 'clubeoab' &&
this.state.productName !== 'clubesimesc' &&  
this.state.productName !== 'clube11' && 
this.state.productName !== 'clube12' && 
this.state.productName !== 'club1' && 
this.state.productName !== 'clube4cs' && 
this.state.productName !== 'clube5' &&
this.state.productName !== 'clube2x' &&
this.state.productName !== 'clube2xcs' &&
this.state.productName !== 'club1' && 
this.state.productName !== 'club2' && 
this.state.productName !== 'club3' && 
this.state.productName !== 'club4' && 
this.state.productName !== 'club5' && 
this.state.productName !== 'club6' && 
this.state.productName !== 'club7' && 
this.state.productName !== 'club8' && 
this.state.productName !== 'club9' && 
this.state.productName !== 'club10' && 
this.state.productName !== 'club11' && 
this.state.productName !== 'club12' &&  
this.state.productName !== 'clubebrinde' ? 
<>
<a style={{ textDecoration: 'none' }} href='https://loja.nsctotal.com.br/assine'>
<div 
  className={classes.btnVoltar} style={{ textDecoration: 'none' }}>
  <span><b>Esta oferta não possui os benefícios do Clube NSC!</b><br /> Clique aqui e confira outras ofertas. </span>
  </div>
</a>
  </>
  : null
}

          {this.state.productName === 'combolight' ?
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Escolha Seu jornal</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>

                <div className={classes.boxJornals}>
                  <JornalAssinatura
                    image={imgDC}
                    name='Diário Catarinense'
                    code='2'
                    onChange={this.handlerChange('channel')} />

                  <JornalAssinatura
                    image={imgJSC}
                    name='Jornal de Santa Catarina'
                    code='4'
                    onChange={this.handlerChange('channel')} />

                  <JornalAssinatura
                    image={imgAN}
                    name='A Notícia'
                    code='12'
                    onChange={this.handlerChange('channel')} />
                </div>

              </ExpansionPanelDetails>
            </ExpansionPanel> : null
          }

          {/***** User Accordion *****/}
          <ExpansionPanel onChange={this.handleExpandUserAccordion} expanded={userAccordionExpanded}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Seus Dados</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <label className={classes.lblForm}>Nome *</label>
              <input
                type="text"
                className={this.state.form_errors.indexOf('given_name') >= 0 ? classes.inpFormErro : classes.inpForm}
                readOnly={this.state.user.document && this.state.user.document.replace(/[./\-_]+/g, '').trim().length > 11 ? true : false}
                value={this.state.user.given_name ? this.state.user.given_name : ''}
                onChange={this.handlerChange('given_name')} /><br />

              <label className={classes.lblForm}>Sobrenome *</label>
              <input
                type="text"
                className={this.state.form_errors.indexOf('family_name') >= 0 ? classes.inpFormErro : classes.inpForm}
                readOnly={this.state.user.document && this.state.user.document.replace(/[./\-_]+/g, '').trim().length > 11 ? true : false}
                value={this.state.user.family_name ? this.state.user.family_name : ''}
                onChange={this.handlerChange('family_name')} /><br />

              <label className={classes.lblForm}>CPF *</label>
              <MaskedInput
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                //(v, vv) => v.replace(/[./\-_]+/g, '').trim().length > 11
                //? [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                //: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                type="text"
                className={this.state.form_errors.indexOf('document') >= 0 ? classes.inpFormErro : classes.inpForm}
                defaultValue={this.state.user.document ? this.state.user.document : ''}
                onChange={this.handlerChange('document')} /><br />

              <label className={classes.lblForm}>Data de Nascimento *</label>
              <MaskedInput
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                type="text"
                className={this.state.form_errors.indexOf('birthdate') >= 0 ? classes.inpFormErro : classes.inpForm}
                defaultValue={this.state.user.birthdate ? this.formatDate(this.state.user.birthdate) : ''}
                onKeyUp={this.handlerChange('birthdate')} /><br />

              <label className={classes.lblForm}>E-mail *</label>
              <input
                type="text"
                className={this.state.form_errors.indexOf('email') >= 0 ? classes.inpFormErro : classes.inpForm}
                readOnly={true}
                value={this.state.user.email ? this.state.user.email : ''}
                onChange={this.handlerChange('email')} /><br />

              <label className={classes.lblForm}>Telefone celular *</label>
              <MaskedInput
                mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                type="text"
                className={this.state.form_errors.indexOf('phone_number_1') >= 0 ? classes.inpFormErro : classes.inpForm}
                defaultValue={this.state.user.phone_number_1 ? this.formatPhoneNumber(this.state.user.phone_number_1) : ''}
                onChange={this.handlerChange('phone_number_1')} /><br />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {this.state.productName === 'combolight' ?
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Endereço de Entrega</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>

                <div className={classes.boxAddressType}>
                  <label className={classes.lblForm}>Tipo de Endereço *</label>
                  <select
                    style={{ textTransform: 'uppercase' }}
                    className={this.state.form_errors.indexOf('address_type') >= 0 ? classes.inpFormErro : classes.inpForm}
                    value={this.state.user.address_type ? this.state.user.address_type : ''}
                    onChange={this.handlerChange('address_type')}>
                    <option value="select">selecione</option>
                    <option value="RESIDENCIAL">RESIDENCIAL</option>
                    <option value="COMERCIAL">COMERCIAL</option>
                  </select><br />
                </div>

                <div className={classes.boxState}>
                  <label className={classes.lblForm}>Estado *</label>
                  <input
                    style={{ textTransform: 'uppercase' }}
                    type="text"
                    className={this.state.form_errors.indexOf('state_code') >= 0 ? classes.inpFormErro : classes.inpForm}
                    value="SC"
                    onChange={this.handlerChange('state_code')}
                    disabled="true" /><br />
                </div>

                <div className={classes.boxCep}>
                  <label className={classes.lblForm}>CEP *</label>
                  <MaskedInput
                    mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                    type="text"
                    className={this.state.form_errors.indexOf('cep') >= 0 ? classes.inpFormErro : classes.inpForm}
                    defaultValue={this.state.user.cep ? this.state.user.cep : ''}
                    onChange={this.handlerChange('cep')} /><br />
                </div>

                {/* <div className={classes.boxBtnCep}>
                <button
                  disabled={!this.state.user.cep || this.state.user.cep.replace(/[.\-_]/g, '').length < 8}
                  className={this.state.user.cep && this.state.user.cep.replace(/[.\-_]/g, '').length >= 8 ? classes.btnCep : classes.btnCepDisabled}
                  type="button"
                  onClick={this.handleClick('btnCep')}>
                    BUSCAR CEP
                </button>
              </div> */}

                <div className={classes.boxCity}>
                  <label className={classes.lblForm}>Cidade *</label>
                  <select
                    style={{ textTransform: 'uppercase' }}
                    className={this.state.form_errors.indexOf('city_code') >= 0 ? classes.inpFormErro : classes.inpForm}
                    value={this.state.user.city_code ? this.state.user.city_code : ''}
                    onChange={this.handlerChange('city_code')}>
                    <option value="select">selecione</option>
                    {this.state.cities.length ? this.state.cities.map(city => (
                      <option value={city.codigo}>{city.nome}</option>
                    )) : null}
                  </select><br />
                </div>

                <div className={classes.boxNeighborhood}>
                  <label className={classes.lblForm}>Bairro *</label>
                  <select
                    disabled={!this.state.user.city_code || this.state.settingNeigborhoods}
                    style={{ textTransform: 'uppercase' }}
                    className={this.state.form_errors.indexOf('neighborhood_code') >= 0 ? classes.inpFormErro : classes.inpForm}
                    value={this.state.user.neighborhood_code ? this.state.user.neighborhood_code : ''}
                    onChange={this.handlerChange('neighborhood_code')}>
                    <option value="select">selecione</option>
                    {this.state.neighborhoods.length ? this.state.neighborhoods.map(neighborhood => (
                      <option value={neighborhood.codigo}>{neighborhood.nome}</option>
                    )) : null}
                  </select><br />
                </div>

                <div className={classes.boxStreet}>
                  <label className={classes.lblForm}>Logradouro *</label>
                  <Autocomplete
                    inputProps={{
                      id: 'states-autocomplete',
                      style: { textTransform: 'uppercase' },
                      type: 'text',
                      className: this.state.form_errors.indexOf('street_code') >= 0 ? classes.inpFormErro : classes.inpForm,
                      disabled: !this.state.user.neighborhood_code || this.state.user.neighborhood_code === 'select'
                    }}
                    value={this.state.user.street_name ? this.state.user.street_name : ''}
                    items={this.state.streets}
                    wrapperStyle={{ width: '100%' }}
                    getItemValue={(item) => item.nome}
                    onSelect={(value, item) => {
                      let user = this.state.user;
                      user.street_code = item.codigo;
                      user.street_name = item.nome;
                      this.setState({ user });
                    }}
                    onChange={this.handlerChange('street_name')}
                    renderMenu={children => (
                      <div className={classes.autocomplete}>
                        {children}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={classes.itemAutocomplete}
                        key={item.codigo}
                      >{item.tipo} {item.nome}</div>
                    )}
                  /><br />
                </div>

                <div className={classes.boxAddressNumber}>
                  <label className={classes.lblForm}>Número *</label>
                  <input
                    style={{ textTransform: 'uppercase' }}
                    type="text"
                    className={this.state.form_errors.indexOf('address_number') >= 0 ? classes.inpFormErro : classes.inpForm}
                    value={this.state.user.address_number}
                    onChange={this.handlerChange('address_number')} /><br />
                </div>

                <div className={classes.boxCity}>
                  <label className={classes.lblForm}>Complemento</label>
                  <select
                    style={{ textTransform: 'uppercase' }}
                    className={this.state.form_errors.indexOf('complement_type') >= 0 ? classes.inpFormErro : classes.inpForm}
                    value={this.state.user.complement_type ? this.state.user.complement_type : ''}
                    onChange={this.handlerChange('complement_type')}>
                    <option value="select">selecione</option>
                    {this.state.complements.length ? this.state.complements.map(complement => (
                      <option value={complement.codigo}>{complement.nome}</option>
                    )) : null}
                  </select><br />
                </div>

                {!!this.state.user.complement_type ?
                  <div className={classes.boxNeighborhood}>
                    <label className={classes.lblForm}>Número Complemento</label>
                    <input
                      style={{ textTransform: 'uppercase' }}
                      className={this.state.form_errors.indexOf('complement_number') >= 0 ? classes.inpFormErro : classes.inpForm}
                      value={this.state.user.complement_number ? this.state.user.complement_number : ''}
                      onChange={this.handlerChange('complement_number')} /><br />
                  </div> : null
                }

                {!!this.state.user.complement_type ?
                  <div className={classes.boxCity}>
                    <label className={classes.lblForm}>Bloco Complemento</label>
                    <input
                      style={{ textTransform: 'uppercase' }}
                      className={this.state.form_errors.indexOf('complement_block') >= 0 ? classes.inpFormErro : classes.inpForm}
                      value={this.state.user.complement_block ? this.state.user.complement_block : ''}
                      onChange={this.handlerChange('complement_block')} /><br />
                  </div> : null
                }

                {!!this.state.user.complement_type ?
                  <div className={classes.boxNeighborhood} >
                    <label className={classes.lblForm}>Referência</label>
                    <input
                      style={{ textTransform: 'uppercase' }}
                      type="text"
                      className={this.state.form_errors.indexOf('complement_reference') >= 0 ? classes.inpFormErro : classes.inpForm}
                      value={this.state.user.complement_reference ? this.state.user.complement_reference : ''}
                      onChange={this.handlerChange('complement_reference')} /><br />
                  </div> : null
                }

              </ExpansionPanelDetails>
            </ExpansionPanel> : null
          }

          {/***** Payment Accordion *****/}
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Pagamento</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            {(
            (this.state.productName === 'digitalanual') ||
            (this.state.productName === 'clube3') ||
            (this.state.productName === 'clube4') ||
            (this.state.productName === 'clube6') ||
            (this.state.productName === 'clube6meses') ||
            (this.state.productName === 'clube6mesescs') ||
            (this.state.productName === 'clube6mesesloja') ||
            (this.state.productName === 'clubeweekads') ||
            (this.state.productName === 'clubeweekcs') ||
            (this.state.productName === 'clubeweekloja') ||
            (this.state.productName === 'clube7') ||
            (this.state.productName === 'clubeclientecs') ||
            (this.state.productName === 'clubeclienteads') ||
            (this.state.productName === 'clubeclienteloja') ||
            (this.state.productName === 'clube8') ||
            (this.state.productName === 'digital6') ||
            (this.state.productName === 'clube9') ||
            (this.state.productName === 'clube10') ||
            (this.state.productName === 'clubesimesc') ||
            (this.state.productName === 'clubeuni') ||
            (this.state.productName === 'clubeoab') ||
            (this.state.productName === 'clube12') ||
            (this.state.productName === 'club1') ||
            (this.state.productName === 'clube4cs') ||
            (this.state.productName === 'clube5') ||
            (this.state.productName === 'clube2x') ||
            (this.state.productName === 'clube2xcs') ||
            (this.state.productName === 'clubebrinde') ||
            (this.state.productName === 'digital5cs') ||
            (this.state.productName === 'digital5') ||
            (this.state.productName === 'digitalc1') ||
            (this.state.productName === 'digitalc2') ||
            (this.state.productName === 'digitalc3') ||
            (this.state.productName === 'digitalc4') ||
            (this.state.productName === 'digitalc5') ||
            (this.state.productName === 'digitalc6') ||
            (this.state.productName === 'digitalc7') ||
            (this.state.productName === 'digitalc8') ||
            (this.state.productName === 'digitalc9') ||
            (this.state.productName === 'digitalc10') ||
            (this.state.productName === 'digitalc11') ||
            (this.state.productName === 'digitalc12') ||
            (this.state.productName === 'clubepromo')) ? (
                <>
                  <input
                    type="radio"
                    className={classes.radioPaymentType1}
                    name="radioPaymentType"
                    id="radioPaymentType1"
                    defaultChecked={true}
                    value='1'
                    hidden={true}
                    onChange={this.handleChangeChkPaymentType}></input>
                </>
              ) : (
                <>
                  <label className={classes.lblForm}>Forma de Pagamento *</label>
                  <div className={classes.radioPaymentType1div}>
                    <input
                      type="radio"
                      className={classes.radioPaymentType1}
                      name="radioPaymentType"
                      id="radioPaymentType1"
                      defaultChecked={true}
                      value='1'
                      onChange={this.handleChangeChkPaymentType}></input>
                    <label for="radioPaymentType1">Cartao de Credito</label>
                  </div>
                  <div className={classes.radioPaymentType2div}>
                    <input
                      type="radio"
                      className={classes.radioPaymentType2}
                      name="radioPaymentType"
                      id="radioPaymentType2"
                      value='2'
                      onChange={this.handleChangeChkPaymentType}></input>
                    <label for="radioPaymentType2">Debito em Conta</label>
                  </div>
                </>
              )}
              <div className={this.state.paymentForm == 1 ? null : classes.paymentFormDisplayNone}>
                <label className={classes.lblForm}>Número do cartão *</label>
                <MaskedInput
                  mask={maskCard}
                  type="text"
                  className={this.state.form_errors.indexOf('card_number') >= 0 ? classes.inpFormErro : classes.inpForm}
                  defaultValue={this.state.user.card_number ? this.state.user.card_number : ''}
                  onChange={this.handlerChange('card_number')} /><br />

                <label className={classes.lblForm}>Nome impresso no cartão *</label>
                <input
                  style={{ textTransform: 'uppercase' }}
                  type="text"
                  className={this.state.form_errors.indexOf('name_card') >= 0 ? classes.inpFormErro : classes.inpForm}
                  value={this.state.user.name_card ? this.state.user.name_card : ''}
                  onChange={this.handlerChange('name_card')} /><br />


                <div className={classes.boxExpiration}>
                  <label className={classes.lblForm}>Validade *</label>
                  <MaskedInput
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    type="text"
                    className={this.state.form_errors.indexOf('expiration_date_card') >= 0 ? classes.inpFormErro : classes.inpForm}
                    defaultValue={this.state.user.expiration_date_card ? this.state.user.expiration_date_card : ''}
                    onChange={this.handlerChange('expiration_date_card')} /><br />
                </div>

                <div className={classes.boxSecurityCode}>
                  <label className={classes.lblForm}>Cod. segurança *</label>
                  <input
                    type="text"
                    maxLength={this.state.user.card_type === 'american-express' ? 4 : 3}
                    className={this.state.form_errors.indexOf('code_card') >= 0 ? classes.inpFormErro : classes.inpForm}
                    value={this.state.user.code_card ? this.state.user.code_card : ''}
                    onChange={this.handlerChange('code_card')} /><br />
                </div>
              </div>
              <div className={this.state.paymentForm == 2 ? null : classes.paymentFormDisplayNone}>
                <label className={classes.lblForm}>Selecione o banco *</label>
                <select
                  className={this.state.form_errors.indexOf('bank_agency') >= 0 ? classes.inpFormErro : classes.bankSelect}
                  value={this.state.bank}
                  onChange={this.handlerChange('bank')}
                >
                  <option value=''></option>
                  {this.state.banks.length ? this.state.banks.map((bank) => { return bank.code !== 1 && bank.code !== 104 ? <option value={bank.code}>{bank.name}</option> : null }) : null}
                </select>
                <div className={classes.bankDataDiv}>
                  <label className={classes.lblForm}>Agência *</label>
                  <MaskedInput
                    mask={[/\d/, /\d/, /\d/, /\d/]}
                    type="text"
                    className={this.state.form_errors.indexOf('bank_agency') >= 0 ? classes.inpFormErro2 : classes.inpForm2}
                    defaultValue={this.state.bank_agency}
                    onChange={this.handlerChange('bank_agency')} />
                </div>
                <div className={classes.bankDataDiv}>
                  <label className={classes.lblForm}>Conta *</label>
                  <MaskedInput
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                    type="text"
                    className={this.state.form_errors.indexOf('bank_account') >= 0 ? classes.inpFormErro2 : classes.inpForm2}
                    defaultValue={this.state.bank_account}
                    onChange={this.handlerChange('bank_account')} />
                </div>
                <div className={classes.bankDataDivLast}>
                  <label className={classes.lblForm}>Dígito *</label>
                  <MaskedInput
                    mask={[/\d/]}
                    className={this.state.form_errors.indexOf('bank_digit') >= 0 ? classes.inpFormErro2 : classes.inpForm2}
                    defaultValue={this.state.bank_digit}
                    onChange={this.handlerChange('bank_digit')} />
                </div>
                <span className={classes.bankAgencyLabel}>Favor não inserir o digito da agência.</span><br />
                <span className={classes.bankAgencyLabel}>Válido apenas para agências de SC!</span>
                <label className={classes.bankInfoLabel}>Após finalizar sua assinatura, verifique junto a seu banco a autorização do débito automático em sua conta corrente.</label>
              </div>

            </ExpansionPanelDetails>
          </ExpansionPanel>

          <br /><br />
          <button className={classes.btnComprar} type="button" onClick={() => this.submitOrder()} style={{  textDecoration: 'none' , display: this.state.showLoader ? 'none' : 'inline-block' }}>CONTINUAR</button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Loja);

